import type {User} from '~/types/user';
import type {ComputedRef} from 'vue';
import type {Bedrijf} from '#graphql/graphql';

export function useRole() {
  const user: ComputedRef<User> = useSofieUserWhenAuthenticated();

  const isEmployee = computed(() => user.value?.is_employee);
  const isManager = computed(() => user.value?.is_manager);
  const isPartner = computed(() => user.value?.organisations?.some((org: Bedrijf) => org.is_ledenvoordeel_partner));
  const isMember = computed(() => user.value?.organisations?.some((org: Bedrijf) => org.is_member));

  return {
    isEmployee,
    isManager,
    isPartner,
    isMember,
  };
}
